@tailwind base;
@tailwind components;
@tailwind utilities;

* {
  color: white;
}

.rti--tag span, .rti--tag button {
  color: #000;
}

.rti--container {
  padding: 0;
}

option {
  color: #000;
}

input, select {
  color: #333;
}

.ck-content * {
  color: #000;
}

.ck-content h2 {
  font-size: 24px;
  font-weight: bold;
}

.ck-content h3 {
  font-size: 20px;
  font-weight: bold;
  }

.ck-content h4 {
  font-size: 18px;
}

.ck-content p {
  font-size: 16px;
  margin-top: 1em;
  margin-bottom: 1em
}
.ck-content ol,
.ck-content ul {
  padding-left: 40px;
}